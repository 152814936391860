import {
  getDefinitionNameByCode,
  getFieldNameByCode,
  getInstanceNameByCode,
} from './mapper'

import axios from 'axios'
import { uniq } from 'loadsh/array'
import { v4 as uuid } from 'uuid'
import { writable } from 'svelte/store'

const API_URL = 'https://europe-west2-synq-functions.cloudfunctions.net'

export const globalInterval = writable(null)

export const messageSettings = writable({
  notification_title: '',
  notification_body: '',
  call_centre_greeting: '',
  agent_from_number: '',
  agent_name: '',
  reference_id: '',
  reference_id_label: '',
  call_centre_submit_button: '',
  call_centre_cancel_button: '',
})

export const pingTimer = writable(null)

export const fbAuth = writable(null)

export const provider = writable(null)

export const users = writable([])

export const collectionPoints = writable([])

export const activeUserTabIndex = writable(0)

export const token = writable('')

export const providerName = writable('')

export const pingTimers = writable({})

export const fieldsDictionary = writable([])

export const tabName = writable('Customer 1')

export const deleteUser = async (id) => {
  let accessToken

  token.subscribe((token) => {
    accessToken = token
  })

  await axios.delete(`${API_URL}/app/customer_connections/${id}`, {
    headers: {
      authorization: accessToken,
    },
  })
}

export const getFieldsDictionary = async () => {
  let accessToken

  token.subscribe((token) => {
    accessToken = token
  })

  const {
    data: { data },
  } = await axios.get(`${API_URL}/app/dictionary/fields`, {
    headers: {
      authorization: accessToken,
    },
  })

  return data
}

export const createNewConnection = async (user, id) => {
  let accessToken
  let settings

  token.subscribe((token) => {
    accessToken = token
  })

  messageSettings.subscribe((data) => (settings = data))

  const newId = uuid()
  await axios.put(
    `${API_URL}/app/customer_connections`,
    {
      code: id || newId,
      phone: user.phone,
      reference_id: user.reference_id,
      collection_point: user.collectionPoint,
      settings: user.settings || settings,
    },
    {
      headers: {
        authorization: accessToken,
      },
    }
  )
}

export const updateConnection = async (user, id) => {
  let accessToken

  token.subscribe((token) => {
    accessToken = token
  })

  await axios.post(
    `${API_URL}/app/customer_connections/${user.id}`,
    {
      phone: user.phone,
      collection_point: user.collectionPoint,
    },
    {
      headers: {
        authorization: accessToken,
      },
    }
  )
}

export const getConnectionById = async (id) => {
  let accessToken

  token.subscribe((token) => {
    accessToken = token
  })

  const { data } = await axios.get(
    `${API_URL}/app/customer_connections/${id}`,
    {
      headers: {
        authorization: accessToken,
      },
    }
  )

  return data
}

export const getConnectionFields = async (id) => {
  let accessToken

  token.subscribe((token) => {
    accessToken = token
  })

  const { data } = await axios.get(
    `${API_URL}/app/customer_connections/fields/${id}`,
    {
      headers: {
        authorization: accessToken,
      },
    }
  )

  return data
}

export const getConnectionData = async (id) => {
  let accessToken

  token.subscribe((token) => {
    accessToken = token
  })

  const { data } = await axios.get(
    `${API_URL}/app/customer_connections/${id}`,
    {
      headers: {
        authorization: accessToken,
      },
    }
  )

  return data
}

export const updateUser = (updateIndex, userToUpdate) => {
  users.update((users) =>
    users.map((user, idx) => {
      if (idx === updateIndex) {
        return userToUpdate
      }

      return user
    })
  )
}

export const closeUser = async (index) => {
  let accessToken
  let points
  let providerData

  token.subscribe((token) => {
    accessToken = token
  })

  collectionPoints.subscribe((_points) => {
    points = _points
  })

  provider.subscribe((value) => (providerData = value))

  users.update((users) => {
    const user = users[index]

    if (!user?.id) {
      return users
    }

    if (!user.isNew) {
      deleteUser(user.id, accessToken)
    }

    // set new empty user if deleting the last user
    if (users.length === 1) {
      messageSettings.set(points[0]?.settings || providerData?.settings)
      users[0] = {
        ...users[0],
        id: uuid(),
        // default collection point
        collectionPoint: points[0]?.code,
        isCancelled: false,
        error: null,
        fields: null,
        phone: '',
        isPending: false,
        isNew: true,
        settings: {},
        userTitle: null,
        reference_id: null,
        next_template: {},
      }

      return users
    } else {
      messageSettings.set(points[0]?.settings || providerData?.settings)
      return users.filter((_, idx) => idx !== index)
    }
  })
}

export const mapFields = (dictionary, fields) => {
  const columns = uniq(fields.map((f) => f.field.code))

  const item = {}
  columns.forEach((col) => {
    item[getFieldNameByCode(dictionary, col)] = fields.filter(
      (cus) => cus.field.code === col
    )
  })

  const result = Object.entries(item).map(([fieldCode, fieldValues]) => {
    const instancesCodes = uniq(fieldValues.map((v) => v.instance.code))

    const instancesArr = []
    instancesCodes.forEach((ins) => {
      const res = fieldValues.filter((v) => v.instance.code === ins)
      instancesArr.push(res)
    })

    const rst = []
    instancesArr.forEach((fld) => {
      const instanceName = getInstanceNameByCode(
        dictionary.find((f) => f.code === fld[0].field.code),
        fld[0].instance.code
      )

      rst.push({
        id: uuid(),
        name: instanceName,
        fields: fld.map((f) => ({
          id: uuid(),
          field_definition: getDefinitionNameByCode(
            dictionary.find((_f) => _f.code === fld[0].field.code),
            f.instanceValue.code
          ),
          value: f.value,
        })),
      })
    })

    return {
      name: getFieldNameByCode(dictionary, fieldCode),
      data: rst,
    }
  })

  return result
}

export const fakeFields = () => [
  {
    field: 'NAME',
    instance: 'DEFAULT',
    field_definition: 'GIVEN_NAME',
    instanceValue: 'GIVEN_NAME',
    value: 'John',
  },
  {
    field: 'NAME',
    instance: 'DEFAULT',
    field_definition: 'FAMILY_NAME',
    instanceValue: 'FAMILY_NAME',
    value: 'Appleseed',
  },
  {
    field: 'EMAIL',
    instance: 'WORK',
    field_definition: 'EMAIL',
    instanceValue: 'EMAIL',
    value: 'John-Appleseed@mac.com',
  },
  {
    field: 'EMAIL',
    instance: 'SCHOOL',
    field_definition: 'EMAIL',
    instanceValue: 'EMAIL',
    value: 'gb.s@c.com',
  },
  {
    field: 'DRIVING_LICENCE',
    instance: 'MAIN',
    field_definition: 'NUMBER',
    instanceValue: 'NUMBER',
    value: 'Cdsc',
  },
  {
    field: 'DRIVING_LICENCE',
    instance: 'MAIN',
    field_definition: 'EXPIRY_DATE',
    instanceValue: 'EXPIRY_DATE',
    value: '2022-06-08T16:48:01.451Z',
  },
  {
    field: 'DRIVING_LICENCE',
    instance: 'MAIN',
    field_definition: 'ISSUE_DATE',
    instanceValue: 'ISSUE_DATE',
    value: '2022-06-08T16:48:05.381Z',
  },
  {
    field: 'DRIVING_LICENCE',
    instance: 'MAIN',
    field_definition: 'COUNTRY_OF_ISSUE',
    instanceValue: 'COUNTRY_OF_ISSUE',
    value: 'United Kingdom',
  },
  {
    field: 'COMPANY',
    instance: 'DEFAULT',
    field_definition: 'COMPANY_NAME',
    instanceValue: 'COMPANY_NAME',
    value: 'Dscs',
  },
]
