<svelte:options tag="lol-svelte" />
<script>
  import firebase from 'firebase/compat/app'
  import { fade } from 'svelte/transition';
  import 'firebase/compat/auth';
import { token } from './store';
    const { NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
        NEXT_PUBLIC_FIREBASE_API_KEY,
        NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
        NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
        NEXT_PUBLIC_FIREBASE_APP_ID,
        NEXT_PUBLIC_FIREBASE_MESUREMNT_ID  } = __myapp;

     export const firebaseConfig = {
  apiKey: NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: NEXT_PUBLIC_FIREBASE_MESUREMNT_ID,
};


  firebase.initializeApp(firebaseConfig);

  const auth = firebase.auth();
  
  let user = null;

  const userMapper = claims => ({
    id: claims.user_id,
    name: claims.name,
    email: claims.email,
    picture: claims.picture
  });

  export const loginWithEmailPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

  
  export const logout = () => auth.signOut();

  auth.onAuthStateChanged(async fireUser => {
    if (fireUser) {
      const userToken = await fireUser.getIdTokenResult();
      user = userMapper(token.claims);
      
     token.set(userToken?.token);
      
    } else {
      user = null;
      console.log(user);
    }
  });
  console.log(user);
  $: loggedIn = user !== null;

  let error = null;

  const loginHandler = async event => {
    const { email, password } = event.target.elements;
    try {
      error = null;
      await loginWithEmailPassword(email.value, password.value);
    } catch (err) {
      error = err;
    }
    
  };


</script>

<main>
<div class="main">
  {#if loggedIn}
  <div>
    <div class="main-logout">
      <h2 class="">{user.email}</h2>
      <button type="button" class="submit-button" on:click={logout}>Logout</button>
    </div>
  </div>
  {:else}
    <div class="login">
      <form on:submit|preventDefault={loginHandler}>
        <div class="div-space">
          <label class="label" for="email">Email</label>
          <input
            class="input"
            id="email"
            type="text"
            placeholder="name@acme.com"
            
          />
        </div>
        <div class="div-space">
          <label class="label" for="password">Password</label>
          <input
            class="input"
            id="password"
            type="password"
            placeholder="******************"
          
          />
          {#if error}
            <div transition:fade class="error">badly formatted.</div>
          {/if}
        </div>
        <div class="main-button">
          <button class="submit-button">Sign In</button>
        </div>
      </form>
    </div>
    {/if}
  
  </div>

</main>

<style>
.main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-logout {
display: flex;
flex-direction: column;
justify-content: center;
  align-items: center;
}
.label {
        font-weight: 600;
        margin-bottom: 8px;
        font-size: 14px;
    }
.input {
        display: block;
        width: 100%;
        height: 48px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 8px;
        border: 1px solid gray;
    }
    .div-space {
      margin-bottom: 1rem;
    }
  .submit-button {
        display: block;
        width: 100%;
        height: 48px;
        color: white;
        background: #500778;
        border-radius: 8px;
        cursor: pointer;
    }
    .error {
      display: block;
        width: 100%;
        height: 48px;
        padding-left: 12px;
        padding-right: 12px;
        color: red;
        text-align: center;
    }

</style>




