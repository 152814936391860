export const getFieldNameByCode = (fields, code) =>
  fields.find((field) => field.code === code)?.base_text ||
  fields.find((field) => field.field === code)?.field?.toLowerCase() ||
  code

export const getInstanceNameByCode = (field, code) => {
  const name =
    field.instances?.find((ins) => ins.code === code)?.base_text || code
  return name === 'Default' ? 'DEFAULT' : name
}

export const getDefinitionNameByCode = (field, code) =>
  field.field_definitions?.find((def) => def.code === code)?.base_text || code
