<svelte:options tag="auth-svelte" />

<script>
  import firebase from 'firebase/compat/app'
  import 'firebase/compat/auth'
  const auth = firebase.auth()

  let user = null

  const userMapper = (claims) => ({
    id: claims.user_id,
    name: claims.name,
    email: claims.email,
    picture: claims.picture,
  })

  export const loginWithEmailPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password)

  export const logout = () => auth.signOut()

  auth.onAuthStateChanged(async (fireUser) => {
    if (fireUser) {
      const token = await fireUser.getIdTokenResult()
      user = userMapper(token.claims)
    } else {
      user = null
    }
  })

  auth.onIdTokenChanged(async function (fireUser) {
    if (fireUser) {
      const token = await fireUser.getIdTokenResult()
      user = userMapper(token.claims)
    } else {
      user = null
    }
  })
  $: loggedIn = user !== null
</script>

<div>
  <slot {user} {loggedIn} {loginWithEmailPassword} {logout} />
</div>
