<svelte:options tag="login-svelte" />
<script>
  import firebase from 'firebase/compat/app'
    import Auth from './Auth.svelte';
    import { fade } from 'svelte/transition';

    //  const { NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    //     NEXT_PUBLIC_FIREBASE_API_KEY,
    //     NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    //     NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    //     NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    //     NEXT_PUBLIC_FIREBASE_APP_ID,
    //     NEXT_PUBLIC_FIREBASE_MESUREMNT_ID  } = __myapp;

     
   


  export const firebaseConfig = {
  // apiKey: NEXT_PUBLIC_FIREBASE_API_KEY,
  // authDomain: NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  // projectId: NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  // storageBucket: NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  // appId: NEXT_PUBLIC_FIREBASE_APP_ID,
  // measurementId: NEXT_PUBLIC_FIREBASE_MESUREMNT_ID,
  apiKey: "AIzaSyAgzPbf0qQGFsbztxALYYXmPeXAnw4k_ns",
  authDomain: "interesting-project-2022.firebaseapp.com",
  projectId: "interesting-project-2022",
  storageBucket: "interesting-project-2022.appspot.com",
  messagingSenderId: "1099415265322",
  appId: "1:1099415265322:web:51876d2d6d36fa1922a353",
  measurementId: "G-62X567EEMG"
};


  firebase.initializeApp(firebaseConfig);

  let loginWithEmailPassword;
  let error = null;

  const loginHandler = async event => {
    const { email, password } = event.target.elements;
    try {
      error = null;
      await loginWithEmailPassword(email.value, password.value);
    } catch (err) {
      error = err;
    }
    console.log(email.value,password.value);
  };


</script>

<main>
<div class="flex flex-grow justify-center items-center">
    <Auth
    useRedirect={true}
    let:user
    let:loggedIn
    bind:loginWithEmailPassword
    let:logout
  >
  {#if loggedIn}
  <div class="w-full max-w-xs">
    <div class="text-center">
      <h2 class="text-2xl mb-5">{user.email}</h2>
      <button type="button" class="hover:bg-purple-700 w-full px-4 py-2 font-bold text-white bg-purple-900 rounded-sm focus:outline-none" on:click={logout}>Logout</button>
    </div>
  </div>
  {:else}
    <div class="w-full max-w-xs">
      <form class="px-8 pt-6 pb-8 bg-white shadow-md" on:submit|preventDefault={loginHandler}>
        <div class="mb-4">
          <label class="block mb-2 text-sm font-bold text-gray-700" for="email">Email</label>
          <input
            class="border w-full py-2 px-3 text-gray-700 mb-3 focus:outline-none"
            id="email"
            type="text"
            placeholder="name@acme.com"
            
          />
        </div>
        <div class="mb-6">
          <label class="block mb-2 text-sm font-bold text-gray-700" for="password">Password</label>
          <input
            class="border w-full py-2 px-3 text-gray-700 mb-3 focus:outline-none"
            id="password"
            type="password"
            placeholder="******************"
          
          />
          {#if error}
            <div transition:fade class="p-2 mb-6 bg-red-300">Eroor</div>
          {/if}
        </div>
        <div class="">
          <button class="hover:bg-purple-700 w-full px-4 py-2 font-bold text-white bg-purple-900 rounded-sm focus:outline-none" type="submit">Sign In</button>
        </div>
        
      </form>
    </div>
    {/if}
    </Auth>
  </div>

</main>




