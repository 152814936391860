<svelte:options tag="test-svelte" />

<script>
  import firebase from 'firebase/compat/app'
  import { fade } from 'svelte/transition'
  import {
    collectionPoints,
    fbAuth,
    fieldsDictionary,
    getConnectionFields,
    getFieldsDictionary,
    mapFields,
    messageSettings,
    provider,
    providerName,
    tabName,
    token,
    users,
  } from './store'
  import axios from 'axios'
  import { v4 as uuid } from 'uuid'

  import 'firebase/compat/auth'
  import { onMount } from 'svelte'

  const {
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    NEXT_PUBLIC_FIREBASE_API_KEY,
    NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    NEXT_PUBLIC_FIREBASE_APP_ID,
    NEXT_PUBLIC_FIREBASE_MESUREMNT_ID,
  } = __myapp

  export const firebaseConfig = {
    apiKey: NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: NEXT_PUBLIC_FIREBASE_MESUREMNT_ID,
  }

  firebase.initializeApp(firebaseConfig)
  const auth = firebase.auth()
  fbAuth.set(auth)

  let user = null
  let isTokenSet = false
  let isTokenLoading = false
  let loaded = false
  let isDataLoading = false
  let disabledButton = false

  const userMapper = (claims) => ({
    id: claims.user_id,
    name: claims.name,
    email: claims.email,
    picture: claims.picture,
  })

  export const loginWithEmailPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password)

  export const logout = async () => {
    await auth.signOut()
    user = null
    provider.set(null)
    providerName.set('')
  }

  $: loggedIn = user !== null
  let error = null
  let isLoggingIn = false

  const loginHandler = async (event) => {
    const { email, password } = event.target.elements
    try {
      isLoggingIn = true
      error = null
      await loginWithEmailPassword(email.value, password.value)
    } catch (err) {
      error = err
    } finally {
      isLoggingIn = false
    }
  }

  onMount(() => {
    console.log('mounted')
  })

  const load = async () => {
    isDataLoading = true
    const points = await axios.get(
      'https://europe-west2-synq-functions.cloudfunctions.net/app/collection_points',
      {
        headers: {
          authorization: $token,
        },
      }
    )

    const _provider = await axios.get(
      'https://europe-west2-synq-functions.cloudfunctions.net/app/provider',
      {
        headers: {
          authorization: $token,
        },
      }
    )

    const pendingUsers = await axios.get(
      'https://europe-west2-synq-functions.cloudfunctions.net/app/customer_connections',
      {
        headers: {
          authorization: $token,
        },
      }
    )

    console.log('user', user)
    const loggedInUser = await axios.get(
      `https://europe-west2-synq-functions.cloudfunctions.net/app/users/firebase/${user.id}`,
      //`http://localhost:5001/synq-functions/europe-west2/app/users/firebase/${user.id}`,

      {
        headers: {
          authorization: $token,
        },
      }
    )
    const fields = await getFieldsDictionary()
    fieldsDictionary.set(fields)

    collectionPoints.set(
      points?.data?.data
        ?.filter((p) => p.status === 'ACTIVE')
        .map((p) => ({
          ...p,
          id: p.code,
        }))
    )

    provider.set(_provider.data.data)
    providerName.set(
      loggedInUser.data.data.first_name + ' ' + loggedInUser.data.data.surname
    )

    // set empty user if no pending users
    if (!pendingUsers?.data?.data?.length) {
      users.set([
        {
          id: uuid(),
          phone: '',
          collectionPoint: $collectionPoints[0]?.code,
          isPending: false,
          fields: null,
          isNew: true,
        },
      ])
      messageSettings.set(
        $collectionPoints[0]?.settings || _provider.data.data?.settings || {}
      )
    } else {
      const _users = Promise.all(
        pendingUsers?.data?.data?.map(async (u) => {
          let fields = null

          if (u.status === 'COMPLETE') {
            const { data: _fields } = await getConnectionFields(u.code)
            fields = _fields
          }

          const point = $collectionPoints.find(
            (point) => point.id === u.collection_point
          )

          if (point) {
            messageSettings.set(
              $collectionPoints[0].settings ||
                _provider.data.data.settings ||
                {}
            )
          }

          const nameFields = fields?.filter((field) => field.field === 'NAME')
          const userTitle = nameFields?.length
            ? nameFields.map((field) => field.value).join(' ')
            : null

          if (userTitle) {
            tabName.set(userTitle)
          }
          return {
            id: u.code,
            phone: u.phone,
            collectionPoint: u.collection_point,
            isNew: false,
            isCancelled: u.status === 'CANCELLED',
            status: u.status,
            error:
              u.status === 'UNKNOWN_PHONE'
                ? 'Could not find a customer with this phone number. Please, use another one'
                : null,
            fields: fields ? mapFields($fieldsDictionary, fields) : null,
            isPending: u.status === 'INITIALISED' || u.status === 'PENDING',
            userTitle,
          }
        })
      )

      // map pending users
      users.set(await _users)
    }

    loaded = true
    isDataLoading = false
    disabledButton = true
  }

  auth.onAuthStateChanged(async (fireUser) => {
    if (fireUser) {
      const userToken = await fireUser.getIdTokenResult()
      user = userMapper(userToken.claims)
      await token.set(`Bearer ${userToken?.token}`)
      isTokenLoading = false
      await load()
    } else {
      user = null
      token.set(null)
      disabledButton = false
      isTokenLoading = false
    }
  })

  auth.onIdTokenChanged(async function (fireUser) {
    if (fireUser) {
      const userToken = await fireUser.getIdTokenResult()
      user = userMapper(userToken.claims)
      await token.set(`Bearer ${userToken?.token}`)
      isTokenLoading = false
      await load()
    } else {
      user = null
      token.set(null)
      disabledButton = false
      isTokenLoading = false
    }
  })

  const slideLeft = () => {
    return {
      duration: 150,
      css: (t) => {
        return `
				transform: translateX(${240 - t * 240}px);
			`
      },
    }
  }
</script>

<main>
  <div class="">
    {#if !$token && !isDataLoading}
      <!--Sign in form-->
      <div class="login">
        <form on:submit|preventDefault={loginHandler}>
          <div class="div-space">
            <label class="label" for="email">Email</label>
            <input
              class="input"
              id="email"
              type="text"
              placeholder="Email address"
            />
          </div>
          <div class="div-space">
            <label class="label" for="password">Password</label>
            <input
              class="input"
              id="password"
              type="password"
              placeholder="Password"
            />
            {#if error}
              <div class="error">Could not login with your credentials</div>
            {/if}
          </div>
          <div class="btn-wrap">
            <button
              class="submit-button"
              disabled={disabledButton || isLoggingIn}>Sign In</button
            >
          </div>
        </form>
      </div>
    {:else if isTokenLoading || isDataLoading}
      <div class="loading-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style="margin: auto; background: none; display: block; shape-rendering: auto;"
          width="32px"
          height="32px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#500778"
            stroke-width="10"
            r="35"
            stroke-dasharray="164.93361431346415 56.97787143782138"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            />
          </circle>
        </svg>
        <p>Loading data...</p>
      </div>
    {:else if $token && loaded}
      <!--Main content-->
      <div class="main">
        <usertabs-svelte />
        <div class="main-content">
          <userform-svelte />
        </div>
      </div>
    {/if}
  </div>
</main>

<style>
  * {
    box-sizing: border-box;
  }

  .login {
    margin: 2rem auto 0 auto;
    padding: 0.5rem;
  }

  .main-content {
    padding: 0.5rem;
  }

  .button-logout {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  .label {
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 14px;
  }

  .loading-container {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loading-container p {
    padding: 0 0.5rem;
    font-size: 1rem;
    margin-top: 1rem;
    text-align: center;
    font-weight: 400;
  }

  .input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 8px;
    border: 1px solid gray;
  }

  .div-space {
    margin-bottom: 1rem;
  }

  .btn-wrap {
    padding-top: 1rem;
  }

  .submit-button {
    display: block;
    width: 100%;
    height: 48px;
    color: white;
    background: #500778;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    transition: all 0.2s;
  }

  .submit-button:hover {
    opacity: 0.75;
    transform: scale(1.02);
  }

  .submit-button:active {
    transform: scale(0.98);
  }

  .submit-button:disabled {
    opacity: 50%;
    cursor: default;
  }

  .logout-button-style {
    display: block;
    width: 13%;
    height: 48px;
    color: white;
    background: #500778;
    border-radius: 8px;
    cursor: pointer;
  }

  .submit-button:disabled {
    opacity: 50%;
  }

  .error {
    display: block;
    width: 100%;
    color: darkred;
    font-size: 12px;
    margin-top: 0.5rem;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
  }
</style>
