<svelte:options tag="app-svelte" />

<script>
  import UserForm from './UserForm.svelte'
  import UserTabs from './UserTabs.svelte'
  import Login from './Login.svelte'
  import Lol from './Lol.svelte'
  import Test from './Test.svelte'
  import {
    collectionPoints,
    fbAuth,
    pingTimer,
    provider,
    providerName,
    users,
  } from './store'
  let isOpen = false

  const slideLeft = () => {
    return {
      duration: 150,
      css: (t) => {
        return `
				transform: translateX(${240 - t * 240}px);
			`
      },
    }
  }

  const handleCTAClick = () => {
    isOpen = !isOpen
  }

  const logout = async () => {
    await $fbAuth.signOut()

    pingTimer.set(null)
    // provider.set(null);
    providerName.set('')
    // collectionPoints.set([]);
  }

  const close = () => {
    isOpen = false
    pingTimer.set(null)
    // provider.set(null);
    providerName.set('')
    // collectionPoints.set([]);
  }
</script>

<main>
  <div>
    <div style="display: flex; justify-content: center">
      <svg
        width="32"
        height="32"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 26.8001C14.7 27.7001 15.7 28.5001 16.9 29.1001C18.1 29.7001 19.3 30.0001 20.5 30.0001C21 30.0001 21.6 29.9001 22.1 29.8001C22.6 29.7001 23.1 29.5001 23.4 29.2001C23.8 28.9001 24.1 28.6001 24.3 28.2001C24.5 27.8001 24.6 27.3001 24.6 26.7001C24.6 26.1001 24.5 25.6001 24.2 25.2001C23.9 24.8001 23.5 24.4001 23 24.1001C22.5 23.8001 21.8 23.5001 21 23.2001C20.2 22.9001 19.3 22.6001 18.3 22.3001C17.3 22.0001 16.4 21.6001 15.5 21.2001C14.6 20.8001 13.7 20.2001 13 19.6001C12.3 18.9001 11.7 18.1001 11.2 17.2001C10.8 16.2001 10.5 15.1001 10.5 13.7001C10.5 12.0001 10.8 10.6001 11.5 9.4001C12.2 8.2001 13.1 7.2001 14.2 6.4001C15.3 5.6001 16.6 5.1001 18 4.7001C19.4 4.3001 20.8 4.2001 22.2 4.2001C23.9 4.2001 25.7 4.5001 27.4 5.1001C29.2 5.7001 30.7 6.7001 32.1 7.9001L38.8 0.800098C38.2 0.300098 37.4 0.100098 36.5 0.100098H4.5C2.3 0.100098 0.5 1.9001 0.5 4.1001V36.1001C0.5 37.2001 1 38.3001 1.7 39.0001L8.9 31.7001L14 26.8001Z"
          fill="url(#paint0_linear_2_1922)"
        />
        <path
          d="M39.1 0.800293L32.4 7.9003L27.7 12.8003C27.1 12.0003 26.3 11.4003 25.2 10.9003C24.2 10.4003 23.2 10.2003 22.3 10.2003C21.8 10.2003 21.3 10.2003 20.8 10.3003C20.3 10.4003 19.9 10.5003 19.5 10.8003C19.1 11.0003 18.8 11.4003 18.5 11.7003C18.2 12.1003 18.1 12.6003 18.1 13.2003C18.1 13.7003 18.2 14.1003 18.4 14.5003C18.6 14.8003 18.9 15.1003 19.3 15.4003C19.7 15.7003 20.2 15.9003 20.8 16.1003C21.4 16.3003 22 16.5003 22.7 16.8003C23.7 17.1003 24.8 17.5003 25.9 17.9003C27 18.3003 28 18.9003 28.9 19.5003C29.8 20.2003 30.6 21.0003 31.2 22.0003C31.8 23.0003 32.1 24.3003 32.1 25.8003C32.1 27.5003 31.8 29.0003 31.1 30.3003C30.5 31.6003 29.6 32.6003 28.5 33.4003C27.4 34.2003 26.2 34.8003 24.8 35.2003C23.5 35.8003 22 36.0003 20.5 36.0003C18.3 36.0003 16.2 35.6003 14.2 34.9003C12.2 34.1003 10.5 33.1003 9.1 31.7003L2 39.0003C2.7 39.7003 3.7 40.1003 4.8 40.1003H36.8C39 40.1003 40.8 38.3003 40.8 36.1003V4.10029C40.8 2.70029 40.1 1.50029 39.1 0.800293Z"
          fill="url(#paint1_linear_2_1922)"
        />
        <path
          d="M27.7001 12.8002C27.1001 12.0002 26.3001 11.4002 25.2001 10.9002C24.2001 10.4002 23.2001 10.2002 22.3001 10.2002C21.8001 10.2002 21.3001 10.2002 20.8001 10.3002C20.3001 10.4002 19.9001 10.5002 19.5001 10.8002C19.1001 11.0002 18.8001 11.4002 18.5001 11.7002C18.2001 12.1002 18.1001 12.6002 18.1001 13.2002C18.1001 13.7002 18.2001 14.1002 18.4001 14.5002C18.6001 14.8002 18.9001 15.1002 19.3001 15.4002C19.7001 15.7002 20.2001 15.9002 20.8001 16.1002C21.4001 16.3002 22.0001 16.5002 22.7001 16.8002C23.7001 17.1002 24.8001 17.5002 25.9001 17.9002C27.0001 18.3002 28.0001 18.9002 28.9001 19.5002C29.8001 20.2002 30.6001 21.0002 31.2001 22.0002C31.8001 23.0002 32.1001 24.3002 32.1001 25.8002C32.1001 27.5002 31.8001 29.0002 31.1001 30.3002C30.5001 31.6002 29.6001 32.6002 28.5001 33.4002C27.4001 34.2002 26.2001 34.8002 24.8001 35.2002C23.5001 35.8002 22.0001 36.0002 20.5001 36.0002C18.3001 36.0002 16.2001 35.6002 14.2001 34.9002C12.2001 34.1002 10.5001 33.1002 9.1001 31.7002L13.9001 26.8002C14.6001 27.7002 15.6001 28.5002 16.8001 29.1002C18.0001 29.7002 19.2001 30.0002 20.4001 30.0002C20.9001 30.0002 21.5001 29.9002 22.0001 29.8002C22.5001 29.7002 23.0001 29.5002 23.3001 29.2002C23.7001 28.9002 24.0001 28.6002 24.2001 28.2002C24.4001 27.8002 24.5001 27.3002 24.5001 26.7002C24.5001 26.1002 24.4001 25.6002 24.1001 25.2002C23.8001 24.8002 23.4001 24.4002 22.9001 24.1002C22.4001 23.8002 21.7001 23.5002 20.9001 23.2002C20.1001 22.9002 19.2001 22.6002 18.2001 22.3002C17.2001 22.0002 16.3001 21.6002 15.4001 21.2002C14.5001 20.8002 13.6001 20.2002 12.9001 19.6002C12.2001 18.9002 11.6001 18.1002 11.1001 17.2002C10.7001 16.2002 10.4001 15.1002 10.4001 13.7002C10.4001 12.0002 10.7001 10.6002 11.4001 9.4002C12.1001 8.2002 13.0001 7.2002 14.1001 6.4002C15.2001 5.6002 16.5001 5.1002 17.9001 4.7002C19.3001 4.3002 20.7001 4.2002 22.1001 4.2002C23.8001 4.2002 25.6001 4.50019 27.3001 5.10019C29.1001 5.70019 30.6001 6.7002 32.0001 7.9002L27.7001 12.8002Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2_1922"
            x1="-4.4872"
            y1="32.4779"
            x2="33.1248"
            y2="-5.134"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#87189D" />
            <stop offset="0.2921" stop-color="#680F88" />
            <stop offset="0.5" stop-color="#500778" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2_1922"
            x1="8.679"
            y1="45.6434"
            x2="46.3339"
            y2="7.98839"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.5" stop-color="#500778" />
            <stop offset="0.7079" stop-color="#680F88" />
            <stop offset="1" stop-color="#87189D" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <p style="text-align: center; font-weight: 500">
      SYNQ Call centre widget demo
    </p>
  </div>
  <button class="cta-button" on:click={handleCTAClick}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 26.8001C14.7 27.7001 15.7 28.5001 16.9 29.1001C18.1 29.7001 19.3 30.0001 20.5 30.0001C21 30.0001 21.6 29.9001 22.1 29.8001C22.6 29.7001 23.1 29.5001 23.4 29.2001C23.8 28.9001 24.1 28.6001 24.3 28.2001C24.5 27.8001 24.6 27.3001 24.6 26.7001C24.6 26.1001 24.5 25.6001 24.2 25.2001C23.9 24.8001 23.5 24.4001 23 24.1001C22.5 23.8001 21.8 23.5001 21 23.2001C20.2 22.9001 19.3 22.6001 18.3 22.3001C17.3 22.0001 16.4 21.6001 15.5 21.2001C14.6 20.8001 13.7 20.2001 13 19.6001C12.3 18.9001 11.7 18.1001 11.2 17.2001C10.8 16.2001 10.5 15.1001 10.5 13.7001C10.5 12.0001 10.8 10.6001 11.5 9.4001C12.2 8.2001 13.1 7.2001 14.2 6.4001C15.3 5.6001 16.6 5.1001 18 4.7001C19.4 4.3001 20.8 4.2001 22.2 4.2001C23.9 4.2001 25.7 4.5001 27.4 5.1001C29.2 5.7001 30.7 6.7001 32.1 7.9001L38.8 0.800098C38.2 0.300098 37.4 0.100098 36.5 0.100098H4.5C2.3 0.100098 0.5 1.9001 0.5 4.1001V36.1001C0.5 37.2001 1 38.3001 1.7 39.0001L8.9 31.7001L14 26.8001Z"
        fill="url(#paint0_linear_2_1922)"
      />
      <path
        d="M39.1 0.800293L32.4 7.9003L27.7 12.8003C27.1 12.0003 26.3 11.4003 25.2 10.9003C24.2 10.4003 23.2 10.2003 22.3 10.2003C21.8 10.2003 21.3 10.2003 20.8 10.3003C20.3 10.4003 19.9 10.5003 19.5 10.8003C19.1 11.0003 18.8 11.4003 18.5 11.7003C18.2 12.1003 18.1 12.6003 18.1 13.2003C18.1 13.7003 18.2 14.1003 18.4 14.5003C18.6 14.8003 18.9 15.1003 19.3 15.4003C19.7 15.7003 20.2 15.9003 20.8 16.1003C21.4 16.3003 22 16.5003 22.7 16.8003C23.7 17.1003 24.8 17.5003 25.9 17.9003C27 18.3003 28 18.9003 28.9 19.5003C29.8 20.2003 30.6 21.0003 31.2 22.0003C31.8 23.0003 32.1 24.3003 32.1 25.8003C32.1 27.5003 31.8 29.0003 31.1 30.3003C30.5 31.6003 29.6 32.6003 28.5 33.4003C27.4 34.2003 26.2 34.8003 24.8 35.2003C23.5 35.8003 22 36.0003 20.5 36.0003C18.3 36.0003 16.2 35.6003 14.2 34.9003C12.2 34.1003 10.5 33.1003 9.1 31.7003L2 39.0003C2.7 39.7003 3.7 40.1003 4.8 40.1003H36.8C39 40.1003 40.8 38.3003 40.8 36.1003V4.10029C40.8 2.70029 40.1 1.50029 39.1 0.800293Z"
        fill="url(#paint1_linear_2_1922)"
      />
      <path
        d="M27.7001 12.8002C27.1001 12.0002 26.3001 11.4002 25.2001 10.9002C24.2001 10.4002 23.2001 10.2002 22.3001 10.2002C21.8001 10.2002 21.3001 10.2002 20.8001 10.3002C20.3001 10.4002 19.9001 10.5002 19.5001 10.8002C19.1001 11.0002 18.8001 11.4002 18.5001 11.7002C18.2001 12.1002 18.1001 12.6002 18.1001 13.2002C18.1001 13.7002 18.2001 14.1002 18.4001 14.5002C18.6001 14.8002 18.9001 15.1002 19.3001 15.4002C19.7001 15.7002 20.2001 15.9002 20.8001 16.1002C21.4001 16.3002 22.0001 16.5002 22.7001 16.8002C23.7001 17.1002 24.8001 17.5002 25.9001 17.9002C27.0001 18.3002 28.0001 18.9002 28.9001 19.5002C29.8001 20.2002 30.6001 21.0002 31.2001 22.0002C31.8001 23.0002 32.1001 24.3002 32.1001 25.8002C32.1001 27.5002 31.8001 29.0002 31.1001 30.3002C30.5001 31.6002 29.6001 32.6002 28.5001 33.4002C27.4001 34.2002 26.2001 34.8002 24.8001 35.2002C23.5001 35.8002 22.0001 36.0002 20.5001 36.0002C18.3001 36.0002 16.2001 35.6002 14.2001 34.9002C12.2001 34.1002 10.5001 33.1002 9.1001 31.7002L13.9001 26.8002C14.6001 27.7002 15.6001 28.5002 16.8001 29.1002C18.0001 29.7002 19.2001 30.0002 20.4001 30.0002C20.9001 30.0002 21.5001 29.9002 22.0001 29.8002C22.5001 29.7002 23.0001 29.5002 23.3001 29.2002C23.7001 28.9002 24.0001 28.6002 24.2001 28.2002C24.4001 27.8002 24.5001 27.3002 24.5001 26.7002C24.5001 26.1002 24.4001 25.6002 24.1001 25.2002C23.8001 24.8002 23.4001 24.4002 22.9001 24.1002C22.4001 23.8002 21.7001 23.5002 20.9001 23.2002C20.1001 22.9002 19.2001 22.6002 18.2001 22.3002C17.2001 22.0002 16.3001 21.6002 15.4001 21.2002C14.5001 20.8002 13.6001 20.2002 12.9001 19.6002C12.2001 18.9002 11.6001 18.1002 11.1001 17.2002C10.7001 16.2002 10.4001 15.1002 10.4001 13.7002C10.4001 12.0002 10.7001 10.6002 11.4001 9.4002C12.1001 8.2002 13.0001 7.2002 14.1001 6.4002C15.2001 5.6002 16.5001 5.1002 17.9001 4.7002C19.3001 4.3002 20.7001 4.2002 22.1001 4.2002C23.8001 4.2002 25.6001 4.50019 27.3001 5.10019C29.1001 5.70019 30.6001 6.7002 32.0001 7.9002L27.7001 12.8002Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_1922"
          x1="-4.4872"
          y1="32.4779"
          x2="33.1248"
          y2="-5.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#87189D" />
          <stop offset="0.2921" stop-color="#680F88" />
          <stop offset="0.5" stop-color="#500778" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_1922"
          x1="8.679"
          y1="45.6434"
          x2="46.3339"
          y2="7.98839"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.5" stop-color="#500778" />
          <stop offset="0.7079" stop-color="#680F88" />
          <stop offset="1" stop-color="#87189D" />
        </linearGradient>
      </defs>
    </svg>
  </button>
  {#if isOpen}
    <div class="content" transition:slideLeft>
      <div class="header">
        <div class="app-title">
          {#if $providerName}
            <svg
              width="18"
              height="18"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          {/if}
          {#if $providerName}
            <div class="name-with-login" on:click={logout}>
              {$providerName}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </div>
          {:else}
            <span>Synq call centre</span>
          {/if}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="close-btn"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          on:click={close}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div class="main-content">
        <test-svelte />
      </div>
    </div>
  {/if}
</main>

<style>
  * {
    box-sizing: border-box;
  }

  .name-with-login {
    display: flex;
    align-items: center;
  }

  .name-with-login svg {
    margin-left: 0.5rem;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .app-title {
    display: flex;
    flex: 1;
    align-items: center;
    font-weight: 500;
    padding: 0.5rem 0;
  }

  .app-title svg {
    margin-right: 8px;
  }

  .cta-button {
    position: fixed;
    right: -0.5rem;
    bottom: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    width: max-content;
    box-shadow: 0px 0px 8px 1px #00000040;
    cursor: pointer;
  }
  .cta-button:hover {
    transition: all 0.4s;
    transform: scale(1.08);
  }

  main {
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  .content {
    overflow: auto;
    width: 400px;
    background: white;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    box-shadow: 0px 0px 8px 1px #00000040;
  }

  .main-content {
    padding: 0 1rem;
  }
  /* .input {
        display: block;
        width: 100%;
        height: 48px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 8px;
        border: 1px solid gray;
    }  */

  .header {
    background: #500778;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    color: white;
  }

  .header span {
    display: block;
    flex: 1;
    color: white;
    font-size: 20px;
  }

  .close-btn {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
